.glide {
  &__arrows {
    display: block;
    width: max-content;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }

  &__arrow {
    background: $pink-gradient;
    border: 5px solid white;
    @include flex(center, center);
    width: 60px;
    height: 60px;
    cursor: pointer;

    &--left {
      display: none;
    }
  }

  &__slide {
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  @include mq($tablet) {
    &__arrows {
      width: 100%;
      transform: translate(0, -50%);
    }

    &__arrow {
      position: absolute;
      transform: translate(0, -50%);

      &--left {
        @include flex(center, center);
        width: 60px;
        left: -20px;
      }

      &--right {
        right: -20px;
      }
    }
  }

  @include mq($mobile) {
    &__arrow {
      &--left {
        left: -10px;
      }

      &--right {
        right: -10px;
      }
    }
  }
}