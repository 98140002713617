.why-us {
  padding: 130px 0;
  padding-top: 0;
  background: $silver;

  &__title {
    margin-bottom: 90px;
  }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 80px;
  }

  &-card {
    width: 100%;
    display: grid;
    grid-template-columns: 64px 1fr;
    grid-column-gap: 20px;
    font-size: 20px;
    line-height: 150%;
    align-items: center;
  }

  @include mq($laptop) {
    padding-bottom: 75px;

    &__title {
      margin-bottom: 70px;
    }
  }

  @include mq($tablet) {
    &__title {
      margin-bottom: 50px;
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 50px;
      grid-row-gap: 50px;
    }
  }

  @include mq($mobile) {
    &__title {
      margin-bottom: 50px;
    }

    &__list {
      grid-template-columns: minmax(0, 1fr);
      grid-row-gap: 30px;
    }

    &-card {
      font-size: 16px;
    }
  }
}