.hero {
  padding-top: 550px;
  padding-bottom: 185px;
  background: $silver-gradient;
  position: relative;
  overflow: hidden;

  &__video {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
  }

  &__inner {
    width: 800px;
    position: relative;
    z-index: 3;
    margin: 0 auto;
    text-align: center;
  }

  &__title {
    margin-bottom: 30px;
    color: white;
  }

  &__desc {
    margin-bottom: 60px;
  }

  &__img {
    position: absolute;
    display: block;
    right: -125px;
    bottom: -300px;
    width: 677px;
    height: 781px;
    z-index: 3;
  }

  &__decor {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
  }

  @include mq($laptop) {
    padding-top: 350px;
    padding-bottom: 145px;

    &__img {
      bottom: -175px;
      width: 510px;
      right: 0;
      height: auto;
    }
  }

  @include mq($tablet) {
    padding-top: 255px;
    padding-bottom: 260px;

    &__video {
      width: auto;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &__inner {
      width: 520px;
    }

    &__title {
      color: inherit;
    }

    &__desc {
      width: 365px;
    }

    &__img {
      position: absolute;
      display: block;
      right: -115px;
      bottom: -260px;
      width: 510px;
      z-index: 2;
    }

    &__decor {
      display: none;
    }
  }

  @include mq($mobile) {
    padding-top: 115px;
    padding-bottom: 150px;

    &__inner {
      width: 100%;
    }

    &__title {
      text-align: center;
    }

    &__desc {
      width: 100%;
      text-align: center;
    }

    &__img {
      display: none;
    }

    &__img {
      display: none;
    }
  }
}