.services {
  background: $silver;
  padding-top: 130px;
  padding-bottom: 150px;

  &__title {
    margin-bottom: 70px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}

.service {
  display: block;
  width: 100%;
  height: 180px;
  position: relative;
  background: $silver-gradient;
  overflow: hidden;
  z-index: 1000;

  span:first-of-type {
    display: block;
    position: absolute;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 30px;
    font-family: $title-font;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 190px;
    z-index: 2;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    z-index: 1;
  }

  &__shine {
    position: absolute;
    top: 0;
    left: -200px;
    height: 100%;
    width: 150px;
    background: rgba(255, 255, 255, 0.5);
    z-index: 3;
  }


  &:hover .service__shine {
    animation: .3s linear shine;
  }
}

@keyframes shine {
  from {
    transform: skewX(-20deg) translateX(0);
  }

  to {
    transform: skewX(-20deg) translateX(700px);
  }
}

@include mq($laptop) {
  .services {
    padding: 75px 0;
  }
}

@include mq($tablet) {
  .services {
    padding-top: 100px;
    padding-bottom: 100px;

    &__title {
      margin-bottom: 50px;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
  }

  .service {
    display: block;
    width: 100%;
    height: 158px;
    position: relative;
    background: $silver-gradient;
    overflow: hidden;

    span:first-of-type {
      max-width: 175px;
    }
  }
}

@include mq($mobile) {
  .services {
    padding-top: 70px;
    padding-bottom: 70px;

    &__list {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
    }
  }

  .service {
    height: 130px;

    span:first-of-type {
      max-width: 135px;
      font-size: 18px;
      line-height: 21px;
      left: 20px;
    }
  }
}