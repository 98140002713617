.table {
  width: 100%;
  border: none;
  border-collapse: collapse;

  tr {
    background: white;
    border-bottom: 2px solid #F4F8FA;
  }

  td {
    padding: 20px;
    font-size: 18px;
  }

  thead {
    tr {
      background: transparent;
      border: none;
    }

    td {
      padding: 5px 20px;
    }
  }


  &__row {
    width: 100%;
    display: grid;
    grid-template-columns: 565px 235px 1fr;
    grid-gap: 30px;
    background: white;
    border-bottom: 2px solid $silver;

    &--head {
      background: transparent;
      border: none;

      .table__col {
        padding: 5px 20px;
      }
    }
  }

  &__col {
    padding: 20px;
    font-size: 18px;
  }
}