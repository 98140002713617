.breadcrumbs {
  @include flex(unset, center);
  margin-bottom: 30px;

  a {
    font-size: 14px;
    line-height: 17px;
    display: inline-block;
    color: $pink;

    &:last-child {
      color: #B8B8B8;
    }

    &:not(:last-child) {
      margin-right: 50px;
      position: relative;

      &:after {
        content: '';
        width: 30px;
        height: 8px;
        background-image: url("../images/arrow-right.svg");
        background-size: cover;
        top: 4px;
        display: block;
        right: -10px;
        position: absolute;
        transform: translateX(100%);
      }
    }
  }
}