.about-page {
  padding: 0;

  &__head {
    position: relative;
    background: $silver;
    padding-top: 60px;
    padding-bottom: 150px;

    &-inner {
      width: 630px;
      margin-right: auto;

      p {
        font-size: 18px;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }

    &-m-img {
      display: none;
    }

    &-img {
      position: absolute;
      top: 110px;
      right: 0;
      width: 865px;
      height: 710px;
      z-index: 2;
    }

    &-container {
      z-index: 2;
    }

    &-decor {
      position: absolute;
      display: block;
      width: 1614px;
      height: 1075px;
      top: 0;
      right: 75px;
      z-index: 1;
    }
  }

  &__title {
    margin-bottom: 30px;
  }

  &__main {
    padding-top: 100px;
    padding-bottom: 100px;
    background: $silver-gradient;
    position: relative;

    &-container {
      display: grid;
      grid-template-columns: 1fr 630px;
      grid-gap: 195px;
      z-index: 2;
    }

    &-img {
      padding-left: 65px;
      @include flex(center, center)
    }

    &-inner {
      p {
        font-size: 18px;
        line-height: 200%;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }

    &-decor {
      position: absolute;
      display: block;
      width: 895px;
      height: 1514px;
      top: -400px;
      left: 0;
      z-index: 1;
    }
  }

  &__footer {
    background: $silver;
    padding-top: 100px;
    padding-bottom: 100px;

    p {
      font-size: 18px;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    p, h2, h3 {
      margin-bottom: 20px;
    }

    h2, h3, h4 {
      color: black;
      font-weight: bold;
    }
  }

  @include mq($laptop) {
    &__head {
      padding-top: 30px;
      padding-bottom: 75px;

      &-inner {
        width: 49%;
      }

      &-img {
        width: 45%;
        height: auto;
      }
    }

    &__main {
      padding: 75px 0;
    }

    &__footer {
      padding: 75px 0;
    }
  }

  @include mq($tablet) {
    padding: 0;

    &__head {
      position: relative;
      background: $silver;
      padding-top: 60px;
      padding-bottom: 100px;

      &-inner {
        width: 100%;
        margin-bottom: 50px;
      }

      &-img {
        display: none;
      }

      &-m-img {
        display: block;
        width: 100%;
        height: auto;
      }

      &-decor {
        display: none;
      }
    }

    &__main {
      &-container {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-gap: 60px;
      }

      &-img {
        padding-left: 0;
        @include flex(center, center)
      }

      &-decor {
        position: absolute;
        display: block;
        width: 895px;
        height: 1514px;
        top: -400px;
        left: 0;
        z-index: 1;
      }
    }
  }

  @include mq($mobile) {
    &__head {
      padding-top: 30px;
      padding-bottom: 50px;
    }

    &__main {
      padding-top: 60px;
      padding-bottom: 50px;

      &-container {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-gap: 30px;
      }

      &-img {
        img {
          width: 128px;
          height: 75px;
        }
      }

      &-decor {
        display: none;
      }
    }

    &__footer {
      padding: 50px 0;
    }
  }
}