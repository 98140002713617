.map {
  overflow: hidden;
  padding-bottom: 46%;
  position: relative;
  height: 0;
  z-index: 1;
  display: block;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.contacts-section {
  padding-top: 65px;
  padding-bottom: 115px;
  background: $silver;

  &--big-pt {
    padding-top: 150px;
  }

  &__container {
    z-index: 2;
  }

  &__title {
    margin-bottom: 80px;
    text-align: center;
  }

  &__inner {
    position: relative;
    padding-left: 220px;
  }

  &__list {
    position: absolute;
    left: 0;
    top: 35px;
  }

  &__m-map {
    display: none;
  }
}

.contact-list {
  padding: 50px;
  background: white;
  padding-bottom: 40px;
  z-index: 2;

  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &--horizontal {
      flex-direction: row;
    }

    .social:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 28px;
    font-family: $title-font;
    margin-bottom: 5px;
  }

  &__value {
    font-size: 18px;
  }
}

@include mq($laptop) {
  .contacts-section {
    padding-top: 35px;
    padding-bottom: 75px;

    &--big-pt {
      padding-top: 75px;
    }
  }
}

@include mq($tablet) {
  .contacts-section {
    padding-top: 50px;

    &--big-pt {
      padding-top: 100px;
    }

    &__title {
      margin-bottom: 50px;
    }

    &__inner {
      position: relative;
      padding: 0;
      display: flex;
      flex-direction: column;

    }

    &__list {
      position: static;
      width: 410px;
      margin: 0 auto;
      margin-bottom: 50px;
    }

    &__m-map {
      display: block;
    }

    .map {
      width: 100%;
      height: auto;
    }

    &__map {
      display: none;
    }
  }
}

@include mq($mobile) {
  .contacts-section {
    padding-top: 25px;

    &--big-pt {
      padding-top: 70px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__list {
      width: 100%;
    }
  }

  .contact-list {
    padding: 30px;

    &__title {
      font-size: 22px;
      line-height: 26px;
    }

    &__value {
      font-size: 16px;
    }
  }

  .map {
    padding-bottom: 100%;
  }
}