.footer {
  width: 100%;
  padding: 30px 0;
  padding-bottom: 40px;
  background: white;
  position: relative;

  &__container {
    @include flex(center, center);
    flex-direction: column;
    width: 1290px;
  }

  &__logo {
    margin-bottom: 20px;
    img {
      display: block;
      width: 100px;
      height: 60px;
    }
  }

  &__contacts {
    margin-bottom: 30px;
    width: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    align-items: center;

    p {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 20px;
      grid-column-start: 1;
      grid-column-end: 3;
      text-align: center;
    }

    a {
      display: flex;

      &:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
        justify-content: center;
        margin-bottom: 5px;
      }

      &:nth-of-type(2) {
        margin-left: auto;
      }

      &:nth-of-type(3) {
        margin-right: auto;
      }
    }
  }

  &__up {
    position: fixed;
    display: none;
    background: linear-gradient(90.2deg, #E7838C 0.17%, #FF9C81 68.4%);;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    right: 32px;
    bottom: 100px;
    cursor: pointer;
    z-index: 1001;

    &--shown {
      display: flex;
    }
  }

  @include mq($tablet) {
    &__container {
      width: 728px;
      margin: 0 auto;
    }

    &__logo {
      img {
        display: block;
        width: 97px;
        height: 57px;
      }
    }

    &__nav {
      .nav-menu {
        display: grid;
        grid-column-gap: 40px;
        grid-row-gap: 20px;
        margin: 0;
        grid-template-columns: max-content max-content;

        li {
          margin: 0;
        }
      }
    }
  }


  @include mq($mobile) {
    &__container {
      width: 100%;
      padding: 0 15px;
      align-items: center;
      flex-direction: column;
    }

    &__logo {
      order: 1;
      margin-bottom: 30px;
      margin-right: 0;
    }

    &__nav {
      order: 3;
      width: 100%;
      margin-right: 0;

      .nav-menu {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-row-gap: 20px;
        text-align: center;
      }
    }

    &__contacts {
      order: 2;
    }
  }
}