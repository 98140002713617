.vacancy-about {
  padding-top: 100px;
  padding-bottom: 100px;
  background: $silver-gradient;

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 140px;

    h2 {
      font-family: $title-font;
      font-size: 36px;
      line-height: 42px;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    p {
      font-size: 18px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    ul {
      @include reset-list;
      width: 100%;
      font-size: 18px;

      li {
        display: block;
        position: relative;
        margin-bottom: 20px;
        padding-left: 24px;

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 8px;
          height: 8px;
          transform: rotate(-45deg);
          background: $black;
          left: 0;
          top: 8px;
        }
      }
    }
  }

  @include mq($laptop) {
    padding: 75px 0;
  }

  @include mq($tablet) {
    &__container {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 50px;

      h2 {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 25px;
      }
    }
  }

  @include mq($mobile) {
    padding-top: 70px;
    padding-bottom: 70px;

    &__container {
      grid-gap: 40px;

      h2 {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}