.vacancy-top {
  padding-top: 60px;
  padding-bottom: 120px;
  position: relative;

  &__title {
    margin-bottom: 30px;
  }

  &__desc {
    font-size: 18px;
    margin-bottom: 80px;
  }

  &__sub-title {
    margin-bottom: 50px;
  }

  &__benfits {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 70px;
    grid-column-gap: 120px;

    .about-item span:first-child {
      color: #C5C5C5;
    }
  }

  &__container {
    z-index: 2;
  }

  &__decor {
    position: absolute;
    display: block;
    width: 1614px;
    height: 1075px;
    top: 0;
    right: 75px;
    z-index: 1;
  }

  @include mq($laptop) {
    padding-top: 30px;
    padding-bottom: 75px;
  }

  @include mq($tablet) {
    padding-bottom: 100px;

    &__benfits {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 50px;
      grid-column-gap: 45px;
    }

    &__decor {
      display: none;
    }
  }

  @include mq($mobile) {
    padding-top: 30px;

    &__sub-title {
      margin-bottom: 30px;
    }

    &__desc {
      font-size: 16px;
      margin-bottom: 70px;
    }

    &__benfits {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-row-gap: 40px;

      .about-item span:first-child {
        color: #EAEDEF;
      }
    }
  }
}