$black: #333333;
$pink: #ECB0B3;
$pink-gradient: linear-gradient(90.2deg, #E8BCC0 0.17%, #F0D3CB 68.4%);
$silver-gradient: linear-gradient(93.85deg, #A8AEB4 2.21%, #CFD7E4 54%, #DEE1E5 97.83%);
$silver: #F4F8FA;

$main-font: 'Montserrat', sans-serif;;
$title-font:'Tenor Sans', sans-serif;

$laptop: 'screen and (max-width: 1445px)';
$tablet: 'screen and (max-width: 1200px)';
$mobile: 'screen and (max-width: 726px)';