.header {
  width: 100%;
  padding: 10px 0;
  background: $silver-gradient;

  &__container {
    @include flex(unset, center);
    width: 1290px;
  }

  &__backdrop {
    display: none;
  }

  &__m-phone {
    display: none;
  }

  &__logo {
    margin-right: 50px;

    img {
      display: block;
      width: 100px;
      height: 60px;
    }
  }

  &__toggler {
    display: none;
  }

  &__nav {
    margin-right: auto;
  }

  &__m-logo {
    display: none;
  }

  &__m-contacts {
    display: none;
  }

  &__contacts {
    @include flex(unset, center);
    width: auto;

    .phone {
      margin-right: 20px;
    }

    .social:not(:last-child) {
      margin-right: 20px;
    }
  }

  &--transparent {
    z-index: 1002;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(244, 248, 250, .4);
  }

  &--fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    background: $silver-gradient;
  }

  &__lang-switch {
    margin-right: auto;
  }

  &__m-lang-switch {
    display: none;
  }
}

.nav {
  &-menu {
    @include reset-list;
    @include flex(unset, center);
    width: auto;

    li {
      margin: 0 15px;
    }

    a {
      color: $black;
      line-height: 20px;

      &:hover {
        opacity: .8;
      }
    }
  }
}

.phone {
  font-size: 17px;
  line-height: 21px;
  font-weight: 600;
  color: $black;
}

.social {
  width: 25px;
  height: 25px;
  display: inline-block;
}

@include mq($tablet) {
  .header {
    width: 100%;
    position: relative;

    &__container {
      width: 728px;
    }

    &__backdrop {
      display: none;
    }

    &__logo {
      margin-right: 60px;
      margin-left: auto;

      img {
        width: 97px;
        height: 57px;
      }
    }

    &__nav {
      display: none;
    }

    &--open  {
      width: 100%;
      height: 100vh;

      .header__container {
        position: static;
      }

      .header__backdrop {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(169, 175, 181, 0.8);
      }

      .header__nav {
        position: absolute;
        z-index: 1002;
        height: 100vh;
        left: 0;
        top: 0;
        @include flex(unset, center);
        flex-direction: column;
        max-width: 300px;
        background: white;
        padding: 30px;
      }

      .header__toggler {
        position: absolute;
        left: 15px;
        top: 15px;
        z-index: 1001;

        svg:nth-child(1) {
          display: none;
        }

        svg:nth-child(2) {
          display: block;
        }
      }
    }

    &__toggler {
      border: none;
      @include flex(center, center);
      width: auto;
      padding: 0;
      margin: 0;
      background: none;
      margin-right: auto;

      svg:nth-child(2) {
        display: none;
      }
    }

    &__contacts {
      @include flex(unset, center);
      width: auto;

      .phone {
        margin-right: 20px;
      }

      .social:not(:last-child) {
        margin-right: 20px;
      }
    }

    &__m-logo {
      display: block;
    }

    &__m-contacts {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      align-items: center;

      a {
        display: flex;

        &:first-child {
          grid-column-start: 1;
          grid-column-end: 3;
          justify-content: center;
        }

        &:nth-child(2) {
          margin-left: auto;
        }

        &:nth-child(3) {
          margin-right: auto;
        }
      }

    }

    &__m-logo {
      margin-bottom: 25px;
    }

    &--transparent {
      z-index: 1002;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent;
    }
  }

  .nav {
    &-menu {
      width: 100%;
      flex-direction: column;
      margin-bottom: 55px;

      li {
        margin: 15px 0;
      }
    }
  }
}

@include mq($mobile) {
  .header {
    &__container {
      width: 100%;
      padding: 0 20px;
    }

    &__logo {
      margin-right: auto;
      margin-left: auto;
    }

    &__m-phone {
      @include flex(center, center);
      width: max-content;
    }

    &__lang-switch {
      display: none;
    }

    &__m-lang-switch {
      display: flex;
    }

    &--open  {
      width: 100%;
      height: 100vh;

      .header__container {
        position: static;
      }

      .header__backdrop {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(169, 175, 181, 0.8);
      }

      .header__nav {
        max-width: 100%;
        width: 100%;
        padding: 30px;
      }

      .header__toggler {
        position: absolute;
        left: 15px;
        top: 15px;
        z-index: 1003;

        svg:nth-child(1) {
          display: none;
        }

        svg:nth-child(2) {
          display: block;
        }
      }
    }

    &__toggler {
      margin-right: 0;
    }

    &__contacts {
      display: none;
    }

    &__m-contacts {
      margin-bottom: 20px;
    }

    &__m-logo {
      margin-bottom: 15px;
    }

    &--transparent {
      z-index: 1002;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent;
    }
  }

  .nav {
    &-menu {
      width: 100%;
      flex-direction: column;
      margin-bottom: 20px;

      li {
        margin: 10px 0;
      }
    }
  }
}