.vacancies-page {
  padding-top: 60px;
  padding-bottom: 230px;
  position: relative;

  &__title {
    margin-bottom: 30px;
  }

  &__desc {
    font-size: 18px;
    margin-bottom: 60px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    margin-bottom: 50px;
  }

  &__more {
    display: flex;
    margin: 0 auto;
  }

  &__container {
    z-index: 2;
  }

  &__decor {
    position: absolute;
    display: block;
    width: 1614px;
    height: 1075px;
    top: 0;
    right: 75px;
    z-index: 1;
  }
}

.vacancy-card {
  display: flex;
  flex-direction: column;

  &__img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__inner {
    padding: 20px;
    padding-bottom: 30px;
    background: white;
  }

  &__title {
    font-size: 26px;
    line-height: 30px;
    font-family: $title-font;
    margin-bottom: 30px;
  }

  &__link {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: $pink;
  }
}

@include mq($laptop) {
  .vacancies-page {
    padding-top: 30px;
    padding-bottom: 75px;

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }

    &__decor {
      display: none;
    }
  }
}

@include mq($tablet) {
  .vacancies-page {
    padding-top: 60px;
    padding-bottom: 165px;

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }

    &__decor {
      display: none;
    }
  }
}


@include mq($mobile) {
  .vacancies-page {
    padding-top: 30px;
    padding-bottom: 100px;

    &__list {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 30px;
    }
  }

  .vacancy-card {
    &__inner {
      padding: 30px 20px;
    }

    &__title {
      font-size: 18px;
      line-height: 21px;
    }
  }
}

