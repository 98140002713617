.service-related {
  padding-top: 50px;
  padding-bottom: 70px;
  position: relative;

  &__title {
    margin-bottom: 50px;
  }

  &__container {
    z-index: 2;
  }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

  @include mq($tablet) {
    padding-top: 50px;
    padding-bottom: 155px;

    &__list {
      @include flex(center, center);
      grid-template-columns: unset;
      grid-gap: unset;

      .service {
        max-width: 354px;
        margin-bottom: 10px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  @include mq($laptop) {
    padding-bottom: 75px;
  }

  @include mq($mobile) {
    padding-top: 40px;
    padding-bottom: 40px;

    &__list {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 20px;
      align-items: center;

      .service {
        max-width: 290px;
        margin: 0 auto;

        &:not(:last-child) {
          margin-right: auto;
        }
      }
    }
  }
}