@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Tenor+Sans&display=swap');
@font-face {
  font-family: 'Times New Roman';
  src: url('../fonts/times_new_roman.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

body {
  background: $silver;
  margin: 0;
  padding: 0;
  font-family: $main-font;
  font-size: 16px;
  line-height: 150%;
  color: $black;
}

a, a:hover, a:focus {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

h1, h2, h3, h4 {
  margin: 0;
  font-weight: normal;
}

p {
  margin: 0;
}

.container {
  display: block;
  width: 1290px;
  margin: 0 auto;
  position: relative;
}

.section {
  padding: 75px 0;
}

.site-main {
  overflow: hidden;
  width: 100%;
  display: block;
}

.title {
  font-family: $title-font;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
}

.sub-title {
  font-family: $title-font;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
}

@include mq($tablet) {
  .container {
    width: 728px;
  }

  body.header-open {
    height: 100vh;
    overflow: hidden;
  }

  .section {
    padding: 100px 0;
  }

  .title {
    font-size: 40px;
    line-height: 47px;
  }

}

@include mq($mobile) {
  .container {
    width: 100%;
    padding: 0 15px;
  }

  .section {
    padding: 70px 0;
  }

  body.header-open {
    height: 100vh;
    overflow: hidden;
  }

  .title {
    font-size: 24px;
    line-height: 28px;
  }

  .sub-title {
    font-size: 24px;
    line-height: 28px;
  }
}