.our-works {
  padding-top: 80px;
  padding-bottom: 65px;
  background: $silver;
  position: relative;

  &__container {
    z-index: 2;
  }

  &__title {
    text-align: center;
    margin-bottom: 90px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-bottom: 70px;
  }

  &__link {
    margin: 0 auto;
    display: flex;

    svg {
      margin-right: 10px;
    }
  }

  &__decor {
    display: block;
    z-index: 1;
    left: 0;
    top: 120px;
    width: 100%;
    height: auto;
    position: absolute;
  }
}

.our-work-item {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 55px;

  &:nth-child(2n) {
    margin-bottom: 0;
    margin-top: 55px;
  }
}
@include mq($laptop) {
  .our-works {
    padding-top: 0;
    padding-bottom: 35px;
  }
}

@include mq($tablet) {
  .our-works {
    padding-top: 50px;
    padding-bottom: 50px;

    &__title {
      margin-bottom: 80px;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      grid-row-gap: 50px;
      margin-bottom: 50px;
    }

    &__link {
      margin: 0 auto;
      display: flex;

      svg {
        margin-right: 10px;
      }
    }

    &__decor {
      display: block;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  .our-work-item {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 30px;

    &:nth-child(2n) {
      margin-bottom: 0;
      margin-top: 30px;
    }
  }
}

@include mq($mobile) {
  .our-works {
    padding-top: 35px;
    padding-bottom: 35px;

    &__title {
      margin-bottom: 30px;
    }

    &__list {
      display: grid;
      grid-gap: 5px;
      grid-row-gap: 30px;
    }

    &__decor {
      display: block;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  .our-work-item {
    margin-bottom: 20px;

    &:nth-child(2n) {
      margin-bottom: 0;
      margin-top: 20px;
    }
  }
}