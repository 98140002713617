.about-section {
  padding: 0;

  &__top {
    padding-top: 100px;
    padding-bottom: 130px;
    background: $silver-gradient;
    position: relative;
  }

  &__bottom {
    padding-top: 405px;
    padding-bottom: 90px;
    background: $silver;
  }

  &__title {
    margin-bottom: 70px;
  }

  &__desc {
    display: block;
    width: 1070px;
    margin: 0 auto;
    text-align: justify;
    font-size: 18px;
    p {
      margin-bottom: 10px;
    }
  }

  &__decor {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 70px;
    grid-column-gap: 120px;
    margin-bottom: 200px;
  }

  &__info {
    width: 410px;
    margin-right: auto;
  }

  &__sub-title {
    margin-bottom: 30px;
  }

  &__img-wrapper {
    position: absolute;
    display: block;
    width: 1055px;
    right: 0;
    bottom: 0;
    transform: translateY(50%);
    z-index: 1;
    //
    //img {
    //  display: block;
    //  width: 1055px;
    //  height: 710px;
    //}
  }

  &__link {
    background: $pink-gradient;
    border: 5px solid white;
    @include flex(center, center);
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }
}

.about-item {
  display: flex;
  height: 166px;
  align-items: flex-end;
  padding-left: 45px;
  padding-bottom: 20px;
  position: relative;

  & > span {
    position: absolute;
    display: block;
    z-index: 1;
    left: 0;
    top: 0;
    font-size: 144px;
    line-height: 166px;
    text-transform: uppercase;
    color: white;
    opacity: 0.45;
    font-family: Times New Roman;
    font-style: italic;
    font-weight: bold;

    span {
      font-size: 88px;
    }
  }

  p {
    z-index: 2;
  }
}

@include mq($laptop) {
  padding: 0;

  .about-section {
    &__top {
      padding-bottom: 75px;
      padding-top: 75px;
    }

    &__list {
      margin-bottom: 75px;
    }

    &__bottom {
      padding-top: 300px;
    }

    &__img-wrapper {
      width: 55%;
    }
  }
}

@include mq($tablet) {
  .about-section {
    padding: 0;

    &__top {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    &__bottom {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &__title {
      margin-bottom: 50px;
    }

    &__desc {
      width: 100%;
    }

    &__decor {
      display: none;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 50px;
      grid-column-gap: 45px;
      margin-bottom: 100px;
    }

    &__info {
      width: 100%;
      margin-bottom: 50px;
    }

    &__sub-title {
      margin-bottom: 40px;
    }

    &__img-wrapper {
      position: static;
      transform: unset;
      padding: 0 40px;
      width: 100%;
    }
  }

  .about-item {
    display: flex;
    height: 166px;
    align-items: flex-end;
    padding-left: 45px;
    padding-bottom: 20px;
    position: relative;

    & > span {
      position: absolute;
      display: block;
      z-index: 1;
      left: 0;
      top: 0;
      font-size: 144px;
      line-height: 166px;
      text-transform: uppercase;
      color: white;
      opacity: 0.45;
      font-family: Times New Roman;
      font-style: italic;
      font-weight: bold;

      span {
        font-size: 88px;
      }
    }

    p {
      z-index: 2;
    }
  }
}

@include mq($mobile) {
  .about-section {
    &__top {
      padding-top: 70px;
      padding-bottom: 30px;
    }

    &__bottom {
      padding-bottom: 35px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__list {
      display: none;
    }

    &__sub-title {
      margin-bottom: 20px;
    }

    &__img-wrapper {
      padding: 0 15px;
    }
  }
}