.lang-switch {
  display: block;
  position: relative;

  &__current {
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;

    svg {
      margin-left: 5px;
    }
  }

  &__inner {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    flex-direction: column;

    a {
      cursor: pointer;
      margin-bottom: 5px;
    }
  }

  &--open {
    .lang-switch__inner {
      display: flex;
    }

    .lang-switch__current svg {
      transform: scaleY(-1);
    }
  }
}