.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 300px;
  margin: 0;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #57342B;
  background: $pink-gradient;
  position: relative;
  overflow: hidden;
  transition: color 0.5s ease;
  border: 2px solid transparent;

  &--inverse {
    background: transparent;
    border-color: $pink;
    color: $pink;
  }

  &__circle{
    display: block;
    background-color: red;
    border-radius: 1000px;
    position: absolute;
    left:0;
    top:0;
    width: 0;
    height: 0;
    margin: 0;
    pointer-events: none;
    z-index: 5;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }

  &:hover {
    color: white;
  }
}

@include mq($mobile) {
  .btn {
    width: 100%;
    font-size: 14px;
    line-height: 150%;
  }
}

.explode-circle {
  animation: explode 0.5s forwards;

}

.desplode-circle{
  animation: desplode 0.5s forwards;
}

@keyframes explode {
  0% {
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
    background-color: rgba(42, 53, 80,0.2);
  }
  100% {
    width: 650px;
    height: 650px;
    margin-left: -325px;
    margin-top: -325px;
    background-color: #F4979F;
  }
}

@keyframes desplode {
  0% {
    width: 650px;
    height: 650px;
    margin-left: -325px;
    margin-top: -325px;
    background-color: #F4979F;
  }
  100% {
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
    background-color: rgba(129, 80, 108,0.6);
  }
}

@include mq($mobile) {
  .btn {
    width: 100%;
  }
}