.vacancy-contact {
  padding-top: 100px;
  padding-bottom: 220px;

  &__title {
    margin-bottom: 70px;
  }

  @include mq($laptop) {
    padding: 75px 0;
  }

  @include mq($tablet) {
    padding-bottom: 205px;

    &__title {
      text-align: center;
      margin-bottom: 50px;
    }
  }

  @include mq($mobile) {
    padding-top: 70px;
    padding-bottom: 100px;

    &__title {
      margin-bottom: 30px;
    }
  }
}