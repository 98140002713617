.form {
  br {
    display: none;
  }

  .wpcf7-not-valid-tip {
    margin-top: 5px;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #FF0000;
  }

  &__row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    margin-bottom: 40px;

    &--single {
      display: block;
    }
  }

  &__submit {
    display: flex;
    margin-left: auto;
    margin-top: 10px;
  }

  &-group {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-label {
    font-size: 18px;
    margin-bottom: 5px;
  }

  &-control {
    width: 100%;
    background: white;
    border: 1px solid #E6E6E6;
    margin: 0;
    color: $black;
    font-size: 18px;
    padding: 16px;

    &.wpcf7-not-valid {
      color: #FF0000;
      border-color: #FF0000;
    }

    &::placeholder {
      color: $black;
    }
  }

  textarea.form-control {
    min-height: 200px;
  }
}

.file-input {
  @include flex(unset, center);
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  color: $pink;
  margin-top: 15px;

  svg {
    margin-right: 10px;
  }

  input {
    display: none;
  }
}

@include mq($mobile) {
  .form {
    &__row {
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 30px;
      margin-bottom: 30px;
    }

    &__submit {
      margin-left: 0;
      margin-top: 10px;
    }

    &-label {
      font-size: 16px;
      margin-bottom: 8px;
    }

    textarea.form-control {
      min-height: 230px;
    }
  }
}