.service-info {
  padding-top: 75px;
  padding-bottom: 60px;
  position: relative;

  &__title {
    margin-bottom: 55px;
  }

  @include mq($tablet) {
    padding-top: 50px;
    padding-bottom: 50px;

    &__title {
      margin-bottom: 30px;
    }
  }

  @include mq($mobile) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}