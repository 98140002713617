.service-page {
  padding-top: 60px;
  padding-bottom: 40px;
  position: relative;

  &__title {
    margin-bottom: 30px;
    width: 630px;
  }

  &__desc, &__content {
    font-size: 18px;

    p, h2, h3 {
      margin-bottom: 20px;
    }

    h2, h3, h4 {
      color: black;
    }
  }

  &__desc {
    margin-bottom: 20px;
  }

  &__more {
    display: flex;
    align-items: center;
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #57342B;
    font-family: $main-font;
    margin-top: 20px;
    outline: none;
    cursor: pointer;

    svg {
      margin-left: 10px;
    }
  }

  &__head {
    width: 630px;
    min-height: 710px;
  }

  &__btn-group {
    @include flex(unset, center);
    //width: max-content;
    margin-bottom: 50px;

    .btn:not(:last-child) {
      margin-right: 30px;
    }
  }

  &__container {
    z-index: 2;
  }

  &__decor {
    position: absolute;
    display: block;
    width: 1614px;
    height: 1075px;
    top: 0;
    right: 75px;
    z-index: 1;
  }

  &__img {
    position: absolute;
    display: block;
    width: 865px;
    height: auto;
    top: 110px;
    right: 0;
    z-index: 2;
  }

  &__m-img {
    display: none;
  }

  @include mq($laptop) {
    padding-top: 30px;
    padding-bottom: 14%;

    &__head {
      width: 49%;
      min-height: 570px;
    }

    &__img {
      width: 45%;
      height: auto;
      top: 110px;
      right: 0;
      z-index: 2;
    }
  }

  @include mq($tablet) {
    padding-bottom: 50px;

    &__head {
      width: 100%;
      min-height: unset;
    }

    &__decor {
      display: none;
    }

    &__img {
      display: none;
    }

    &__m-img {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 30px;
    }
  }

  @include mq($mobile) {
    padding-top: 30px;
    padding-bottom: 35px;

    &__btn-group {
      width: 100%;

      .btn:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}