@mixin flex ($justify: flex-start, $align: center) {
  display: flex;
  flex-wrap: wrap;
  align-items: $align;
  justify-content: $justify;
  width: 100%;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}


@mixin mq($media) {
  @media #{$media} {
    @content;
  }
}
